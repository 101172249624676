// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-routes-landing-js": () => import("/home/xiang/Desktop/BMW/bmw-front/src/routes/landing.js" /* webpackChunkName: "component---src-routes-landing-js" */),
  "component---src-routes-proposal-js": () => import("/home/xiang/Desktop/BMW/bmw-front/src/routes/proposal.js" /* webpackChunkName: "component---src-routes-proposal-js" */),
  "component---src-routes-results-js": () => import("/home/xiang/Desktop/BMW/bmw-front/src/routes/results.js" /* webpackChunkName: "component---src-routes-results-js" */),
  "component---src-pages-404-js": () => import("/home/xiang/Desktop/BMW/bmw-front/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

